<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <Login/> -->
  <router-view></router-view>
</template>

<script>
// import Login from './views/login'
// import Home from './views/home/home'

export default {
  name: 'App'
  // components: {
  //   // Login,
  //   Home
  // }
}
</script>

<style>
#app {
  height: 100%;
  width: 100%;
  font-size: 14px;
  /* 用户在页面选中文本 */
  -webkit-user-select: text; 
  overflow-y: auto;
  background: #f2f0f5;
  /* background: #f7f7f7; */
}
</style>