import {
    createRouter,
    createWebHashHistory
} from 'vue-router'

const router = createRouter({
    history: createWebHashHistory('/pro/'),
    // history: createWebHashHistory(),
    // history: createWebHistory(),
    routes: [{
            name: 'home',
            path: '/',
            meta: { isLogin: true },
            // component: resolve => require(['../views/home/home'], resolve),
            component: () => require.ensure([], (require) => require('../views/home/home')),
            // component: () =>
            //     import ('../views/home/home'),
            children: [
                {
                    name:'createorder',
                    path:'/createorder',
                    meta:{ keepAlive:true },
                    component: () => require.ensure([], (require) => require('../views/home/CreateOrder/createOrder'))
                },
                //业务大厅
                {
                    name:'business_hall',
                    path:'/business_hall',
                    meta:{ keepAlive:false },
                    component: () => require.ensure([], (require) => require('../views/home/business_hall/business_hall'))
                },
                {
                    name:'tonglianpay',
                    path:'/tonglianpay',
                    meta:{ keepAlive:false },
                    component: () => require.ensure([], (require) => require('../views/home/tonglianpay'))
                },
                //共享客户订单
                {
                    name:'share_order',
                    path:'/share_order',
                    meta:{ keepAlive:false },
                    component: () => require.ensure([], (require) => require('../views/home/business_hall/share_order'))
                },

                

                // 财务管理
                
                {
                    name: 'collection-reconciliation',
                    path: '/collection-reconciliation',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/CollectionReconciliation'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/CollectionReconciliation'))
                        // component: () =>
                        //     import ('../views/home/finance/CollectionReconciliation')
                },
                {
                    name: 'payment-reconciliation',
                    path: '/payment-reconciliation',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/PaymentReconciliation'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/PaymentReconciliation'))
                        // component: () =>
                        //     import ('../views/home/finance/PaymentReconciliation')
                },
                {
                    name: 'onAccountsSum',
                    path: '/onAccountsSum',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/ReceivablesSummary'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/onAccountsSum'))
                        // component: () =>
                        //     import ('../views/home/finance/ReceivablesSummary')
                },
                // {
                //     name: 'receivables-summary',
                //     path: '/receivables-summary',
                //     meta: { keepAlive: false },
                //     // component: resolve => require(['../views/home/finance/ReceivablesSummary'], resolve)
                //     component: () => require.ensure([], (require) => require('../views/home/finance/ReceivablesSummary'))
                //         // component: () =>
                //         //     import ('../views/home/finance/ReceivablesSummary')
                // },
                // {
                //     name: 'payable-summary',
                //     path: '/payable-summary',
                //     meta: { keepAlive: false },
                //     // component: resolve => require(['../views/home/finance/PayableSummary'], resolve)
                //     component: () => require.ensure([], (require) => require('../views/home/finance/PayableSummary'))
                //         // component: () =>
                //         //     import ('../views/home/finance/PayableSummary')
                // },
                {
                    name: 'fund-details',
                    path: '/fund-details',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/FundDetails'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/FundDetails'))
                        // component: () =>
                        //     import ('../views/home/finance/FundDetails')
                },
                {
                    name: 'income-expenditure',
                    path: '/income-expenditure',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/IncomeExpenditure'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/IncomeExpenditure'))
                        // component: () =>
                        //     import ('../views/home/finance/IncomeExpenditure')
                },
                {
                    name: 'achievements',
                    path: '/achievements',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/Achievements'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/Achievements'))
                        // component: () =>
                        //     import ('../views/home/finance/Achievements')
                },
                {
                    name: 'payslip',
                    path: '/payslip',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/Payslip'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/Payslip'))
                        // component: () =>
                        //     import ('../views/home/finance/Payslip')
                },
                // {
                //     name: 'financial-statements',
                //     path: '/financial-statements',
                //     meta: { keepAlive: false },
                //     // component: resolve => require(['../views/home/finance/FinancialStatements'], resolve)
                //     component: () => require.ensure([], (require) => require('../views/home/finance/FinancialStatements'))
                //         // component: () =>
                //         //     import ('../views/home/finance/FinancialStatements')
                // },
                {
                    name: 'account',
                    path: '/account',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/Account'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/Account'))
                        // component: () =>
                        //     import ('../views/home/finance/Account')
                },
                {
                    name: 'Invoicing',
                    path: '/Invoicing',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/Invoicing'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/finance/Invoicing'))
                        // component: () =>
                        //     import ('../views/home/finance/Invoicing')
                },
                
                
                // 数据中心
                {
                    name: 'report_summary',
                    path: '/report_summary',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/Invoicing'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/dashboard/ReportSummaryView'))
                    // component: () =>
                    //     import ('../views/home/finance/Invoicing')
                },
                // 
                {
                    name: 'report_view',
                    path: '/report_view',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/finance/Invoicing'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/dashboard/ReportSummary'))
                    // component: () =>
                    //     import ('../views/home/finance/Invoicing')
                },
                // {
                //     name: 'orderAmountChangerecord',
                //     path: '/orderAmountChangerecord',
                //     meta: { keepAlive: false },
                //     // component: resolve => require(['../views/home/finance/Invoicing'], resolve)
                //     component: () => require.ensure([], (require) => require('../views/home/finance/orderAmountChangerecord'))
                //         // component: () =>
                //         //     import ('../views/home/finance/Invoicing')
                // },
                

                // 业务管理
                {
                    name: 'customer',
                    path: '/customer',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/business/customer/customer'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/business/customer/customer'))
                        // component: () =>
                        //     import ('../views/home/business/customer/customer'),
                },
                {
                    name: 'product',
                    path: '/product',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/business/product/product'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/business/product/product'))
                        // component: () =>
                        //     import ('../views/home/business/product/product')
                },
                {
                    name: 'service',
                    path: '/service',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/business/service/service'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/business/service/service'))
                        // component: () =>
                        //     import ('../views/home/business/service/service')
                },
                {
                    name: 'supplier',
                    path: '/supplier',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/business/supplier/supplier'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/business/supplier/supplier'))
                        // component: () =>
                        //     import ('../views/home/business/supplier/supplier')
                },
                {
                    name: 'assistant',
                    path: '/assistant',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/business/businessAssistant/assistant'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/business/businessAssistant/assistant'))
                        // component: () =>
                        //     import ('../views/home/business/businessAssistant/assistant')
                },


                //会员管理
                {
                    name: 'vip',
                    path: '/vip',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/PriceTemplate'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/vip/vip'))
                        // component: () =>
                        // import ('../views/home/system/PriceTemplate'),
                },
                {
                    name: 'vip_customer',
                    path: '/vip_customer',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/PriceTemplate'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/vip/vip_customer'))
                        // component: () =>
                        // import ('../views/home/system/PriceTemplate'),
                },
                {
                    name: 'vip_Recharge_consumption_records',
                    path: '/vip_Recharge_consumption_records',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/PriceTemplate'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/vip/vip_Recharge_consumption_records'))
                        // component: () =>
                        // import ('../views/home/system/PriceTemplate'),
                },

                // 审批管理
                {
                    name: 'staffexamine',
                    path: '/staffexamine',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/examineAndApprove/StaffExamine'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                {
                    name: 'refunded',
                    path: '/refunded',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/examineAndApprove/refunded'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },


                // 系统管理
                {
                    name: 'company-information',
                    path: '/company-information',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/CompanyInformation'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/system/CompanyInformation'))
                        // component: () =>
                        //     import ('../views/home/system/CompanyInformation'),
                },
                {
                    name: 'staff',
                    path: '/staff',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/Staff'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/system/Staff'))
                        // component: () =>
                        //     import ('../views/home/system/Staff'),
                },
                {
                    name: 'permission',
                    path: '/permission',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/Permission'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/system/Permission'))
                        // component: () =>
                        //     import ('../views/home/system/Permission'),
                },
                {
                    name: 'price-template',
                    path: '/price-template',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/PriceTemplate'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/system/PriceTemplate'))
                        // component: () =>
                        // import ('../views/home/system/PriceTemplate'),
                },
                {
                    name: 'process-management',
                    path: '/process-management',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/system/ProcessManagement'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                {
                    name: 'face-sheet',
                    path: '/face-sheet',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/system/FaceSheet'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                {
                    name: 'label-manage',
                    path: '/label-manage',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/system/LabelManage'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                {
                    name: 'login-authentication',
                    path: '/login-authentication',
                    meta: { keepAlive: false },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/system/LoginAuthentication'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                // {
                //     name: 'custom-print',
                //     path: '/custom-print',
                //     meta: { keepAlive: false },
                //     component: () =>
                //         import ('../views/home/system/CustomPrint'),
                // },

                //员工绩效
                {
                    name: 'performance',
                    path: '/performance',
                    meta: { keepAlive: false,},
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/application/performance'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                // 短信管理
                {
                    name: 'text_message',
                    path: '/text_message',
                    meta: {keepAlive: false,},
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../views/home/application/textMessage'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                {
                    name: 'order_search',
                    path: '/order_search',
                    meta: { 
                        keepAlive: true,
                        orderNo:null
                     },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../components/searchResult/SearchOrder'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                {
                    name: 'customer_search',
                    path: '/customer_search',
                    meta: { 
                        keepAlive: true,
                        orderNo:null
                     },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../components/searchResult/SearchCustomer'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
                {
                    name: 'IE_search',
                    path: '/IE_search',
                    meta: { 
                        keepAlive: true,
                        orderNo:null
                     },
                    // component: resolve => require(['../views/home/system/FaceSheet'], resolve)
                    component: () => require.ensure([], (require) => require('../components/searchResult/SearchIE'))
                        // component: () =>
                        //     import ('../views/home/system/FaceSheet'),
                },
            ]
        },
        {
            name: 'login',
            path: '/login',
            // component: resolve => require(['../views/login'], resolve)
            component: () => require.ensure([], (require) => require('../views/login'))
                // component: () =>
                //     import ('../views/login')
        },
        // {
        //     name: 'register',
        //     path: '/register',
        //     // component: resolve => require(['../views/register'], resolve)
        //     component: () => require.ensure([], (require) => require('../views/register'))
        //         // component: () =>
        //         //     import ('../views/register')
        // },

    ]
})

const reset = () => ({
    // mode: 'history', //require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: router
})

export function resetRouter() {
    const newRouter = reset()
    router.matcher = newRouter.matcher // reset router
}

export default router