import Cookies from 'js-cookie'

const TokenKey = 'token-Key'
const ymsToken = 'ymstoken'
const MenuList = 'Menu-List'
    // const Menorize = 'Menorize'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getYmsToken() {
    return Cookies.get(ymsToken)
}

export function setYmsToken(Ymstoken) {
    return Cookies.set(ymsToken, Ymstoken)
}

export function removeYmsToken() {
    return Cookies.remove(ymsToken)
}

//菜单tab缓存
export function getMenulist() {
    return Cookies.get(MenuList)
}

export function setMenulist(menu) {
    return Cookies.set(MenuList, menu)
}

export function removeMenulist() {
    return Cookies.remove(MenuList)
}
// export function getMenorize() {
//   return Cookies.get(Menorize)
// }

// export function setMenorize(MenorizePassword){
//   return Cookies.set(Menorize,MenorizePassword)
// }

// export function removeMenorize(){
//   return Cookies.remove(Menorize)
// }