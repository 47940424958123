import request from '../unit/request'

//登录
export function login(data) {
    return request({
        url: '/kirincs-store/wx/ma/login/passwd',
        method: 'post',
        data
    })
}

// 退出登录
export function logout(data) {
    return request({
        url: '/kirincs-store/api/store/user/logout',
        method: 'get',
        params:data
    })
}
//获取用户信息
export function getuser(data) {
    const userName = data
    return request({
        url: '/printer/api/login/getUser',
        method: 'get',
        params: { userName }
    })
}

// 注册
export function register(data) {
    return request({
        url: '/kirincs-user/api/store/user/register',
        method: 'post',
        data: {
            username: data.username,
            password: data.password,
            phone: data.phone,
            nickname: data.nickname,
            tenantName: data.tenantName,
        }
    })
}
// 修改密码
export function setPassword(data) {
    return request({
        url: '/kirincs-user/api/user/change/password',
        method: 'post',
        data
    })
}

//生成小程序登陆二维码
export function wxminiCode (scene){
    // console.log(scene)
    return request({
        url:'/kirincs-miniapp/wx/'+scene+'/code/unlimited',
        method:'post',
        responseType:'blob'
    })
}

//扫码登陆监听
export function wxLogin(data){
    return request({
        url:'/kirincs-store/wx/ma/scene',
        method:'get',
        params:data
    })
}

//登陆管理员验证
export function adminVerif(data){
    return request({
        url:'/kirincs-store/wx/ma/verify',
        method:'post',
        data
    })
}

//登陆认证
export function loginAuthenticationData(data){
    return request({
        url:'/kirincs-store/wx/ma/verify/list',
        method:'get',
        params:data
    })
}
//登陆认证移除白名单
export function deleteLoginAuthentication(data){
    return request({
        url:`/kirincs-store/wx/ma/verify/delete/${data}`,
        method:'delete',
    })
}

//查询门店列表
export function getStoreData(data){
    return request({
        url:'/kirincs-store/wx/ma/user/store',
        method:'get',
        params:data
    })
}