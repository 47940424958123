import router from './router'
// import store from './store'
import { ElMessage } from 'element-plus'
import { getYmsToken } from './unit/auth' // get token from cookie


router.beforeEach((to, form, next) => {
    const hasToken = getYmsToken()
    // console.log(hasToken)
    if (to.meta.isLogin) {
        //进入守卫
        if (hasToken) {
            //进入下一页
            // store.dispatch('getuser')
            next();
        } else {
            // 无token返回login
            ElMessage({
                message: '请先登录!',
                type: "warning"
            });
            next({
                path: '/login',
                query: { redirect: to.fullPath } //将跳转的路由path作为参数，登录成功后跳转该路由
            })
        }
    } else {
        //免守卫
        next();
    }
});