import { createStore } from 'vuex'
import router from '@/router'
import { getToken, setYmsToken } from '../unit/auth'
import { login } from '../api/user.js'

const store = createStore({
    state: {
        tokenKey: getToken(),
        nickname: null,
        userName: null,
        phone: null,
        setInt:null,
        setTime:null,
        roleList: [],
        permissionList: [],
        orgName: null,
        orgId: null,
        logoPath: localStorage.getItem('logoPath'),
        storeName: localStorage.getItem('storeName'),
        storeCode: null,
        msgCount: localStorage.getItem('msgCount') == 0 || localStorage.getItem('msgCount') == null || localStorage.getItem('msgCount') == undefined || localStorage.getItem('msgCount') === "undefined" ? '' : localStorage.getItem('msgCount'),
        menuactive: '0-0',
        tabs: [
            { menuactive: '0-0', title: '业务大厅', path: 'business_hall' },
            // { menuactive: '2-1', title: '新建销售单', path: 'neworder' },
        ],
        searchOrderSearchWords:"",
        searchOrderFilter:"",        
        searchCustomerSearchWords:"",
        searchIESearchWords:"",
        shortcuts: [{
                text: '今天',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    let y = start.getFullYear()
                    let m = start.getMonth() + 1
                    let d = start.getDate()
                    let startTime = y + '-' + m + '-' + d
                    start.setTime(start.getTime())
                    return [startTime, end]
                },
            },
            {
                text: '昨天',
                value: () => {
                    // const end = new Date()
                    // const start = new Date()
                    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                    // end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
                    // return [start, end]
                    const time = new Date()
                    let y = time.getFullYear()
                    let m = time.getMonth() + 1
                    let sd = time.getDate() - 1
                    let ed = time.getDate()
                    let startTime = y + '-' + m + '-' + sd
                    const endTime = new Date((time.getFullYear()) + '-' + (time.getMonth() + 1) + '-' +(time.getDate()))
                    endTime.setTime(endTime.getTime() - 1000)
                    return [startTime,endTime]
                },
            },
            {
                text: '本月',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    let y = start.getFullYear()
                    let m = start.getMonth() + 1
                    let startTime = y + '-' + m + '-01'
                    return [startTime, end]
                },
            },
            {
                text: '上个月',
                value: () => {
                    // const time = new Date()
                    // let y = time.getFullYear()
                    // let sm = time.getMonth()
                    // const end = new Date((time.getFullYear()) + '-' + (time.getMonth() + 1) + '-01')
                    // let em = end.getMonth() + 1
                    // let ed = end.getDate() - 1
                    // let startTime = y + '-' + sm + '-01'
                    // let endTime = y + '-' + em + '-' + ed
                    // return [startTime, endTime]

                    const time = new Date()
                    let y = time.getFullYear()
                    let sm = time.getMonth()
                    const end = new Date((time.getFullYear()) + '-' + (time.getMonth() + 1) + '-01 00:00:00')
                    end.setTime(end.getTime() - 1000)
                    let startTime = y + '-' + sm + '-01'
                    return [startTime, end]
                },
            },
            {
                text: '最近一周',
                value: () => {
                    // const end = new Date()
                    // const start = new Date()
                    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    // return [start, end]

                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
                    const st = new Date(start)
                    let y = st.getFullYear()
                    let sm = st.getMonth() + 1
                    let sd = st.getDate()
                    let startTime = y + '-' + sm + '-' + sd
                    return [startTime, end]
                },
            },
            {
                text: '最近一个月',
                value: () => {
                    // const end = new Date()
                    // const start = new Date()
                    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    // return [start, end]

                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    const st = new Date(start)
                    let y = st.getFullYear()
                    let sm = st.getMonth() + 1
                    let sd = st.getDate()
                    let startTime = y + '-' + sm + '-' + sd
                    return [startTime, end]
                },
            },
            {
                text: '最近三个月',
                value: () => {
                    // const end = new Date()
                    // const start = new Date()
                    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    // return [start, end]

                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    const st = new Date(start)
                    let y = st.getFullYear()
                    let sm = st.getMonth() + 1
                    let sd = st.getDate()
                    let startTime = y + '-' + sm + '-' + sd
                    return [startTime, end]
                },
            },
        ],
        isPaytype: false,
        orderDetailDraw:false,
        orderNoDetail:null
    },

    mutations: {
        openOrderDetail(state,data){
            state.orderNoDetail = data
            state.orderDetailDraw = true
        },
        closeOrderDetail(state){
            state.orderNoDetail = null
            state.orderDetailDraw = false
        },
        setSearch(state, data){
            if(data.searchType=='订单搜索'){
                if(data.is==true){
                    state.searchOrderFilter=data.filter
                    state.searchOrderSearchWords = ''
                }else{
                    state.searchOrderFilter=''
                    state.searchOrderSearchWords = data.searchWords
                }
            }else if(data.searchType=="客户搜索"){
                state.searchCustomerSearchWords = data.searchWords
            }else if(data.searchType=="日常收支搜索"){
                state.searchIESearchWords=data.searchWords
            }
            // console.log(state)
        },
        loginSet(state, loginInfo) {
            console.log(loginInfo)
            state.nickname = loginInfo.nickname
            state.userName = loginInfo.userName
            state.phone = loginInfo.phone
            state.roleList = loginInfo.roleList
            state.permissionList = loginInfo.permissionList
            state.logoPath = loginInfo.logoPath
            state.orgName = loginInfo.orgName
            state.orgId = loginInfo.orgId
            state.storeCode = loginInfo.storeCode
            state.storeName = loginInfo.storeName
            state.msgCount = loginInfo.msgCount == 0 || loginInfo.msgCount == '' || loginInfo.msgCount == undefined ? '' : loginInfo.msgCount
        },
        logout(state){
            state.tabs= [
                { menuactive: '0-0', title: '业务大厅', path: 'business_hall' }
            ]
            state.menuactive = '0-0'
        },
        setMenactive(state, data) {
            state.menuactive = data
        },
        addTab(state, data) {
            state.tabs.push(data)
        },
        setmenuactive(state,data){
            state.menuactive = data
        },
        removeTab(state, data) {
            if (data.routename == data.path) {
                let routePath = state.tabs[data.index - 1].path
                let menuactive = state.tabs[data.index - 1].menuactive
                state.menuactive = menuactive
                router.push({ path: '/' + routePath })
                state.tabs.splice(data.index, 1)
            } else {
                state.tabs.splice(data.index, 1)
            }
        },
        //清除当前tab页并跳转当前关闭的前一个tab
        deltabs(state, data) {
            let index = state.tabs.findIndex(item => item.path == data)
            state.tabs.splice(index, 1)
            if (data == "createorder") {
                let flag = true
                state.tabs.forEach(item => {
                    if (item.path == "business_hall") {
                        flag = false
                    }
                })
                if (flag) {
                    state.tabs.push({ menuactive: '0-0', title: '业务大厅', path: 'business_hall' })
                    router.push({ path: 'business_hall' })
                    state.menuactive = '0-0'
                } else {
                    router.push({ path: 'business_hall' })
                    state.menuactive = '0-0'
                }
            }

            if (data == "neworder") {
                let flag = true
                state.tabs.forEach(item => {
                    if (item.path == "orderfollowup") {
                        flag = false
                    }
                })
                if (flag) {
                    state.tabs.push({ menuactive: '2-2', title: '销售单跟进', path: 'orderfollowup' })
                    router.push({ path: 'orderfollowup' })
                    state.menuactive = '2-2'
                } else {
                    router.push({ path: 'orderfollowup' })
                    state.menuactive = '2-2'
                }
            }

            if (data == "newdesign") {
                let flag = true
                state.tabs.forEach(item => {
                    if (item.path == "designfollowup") {
                        flag = false
                    }
                })
                if (flag) {
                    state.tabs.push({ menuactive: '3-2', title: '设计单跟进', path: 'designfollowup' })
                    router.push({ path: 'designfollowup' })
                    state.menuactive = '3-2'
                } else {
                    router.push({ path: 'designfollowup' })
                    state.menuactive = '3-2'
                }
                // router.push({ path: 'designfollowup' })
                // state.menuactive = '3-2'
            }

            if (data == "newsupplier") {
                let flag = true
                state.tabs.forEach(item => {
                    if (item.path == "supplierfollowup") {
                        flag = false
                    }
                })
                if (flag) {
                    state.tabs.push({ menuactive: '3-2', title: '生产单跟进', path: 'supplierfollowup' })
                    router.push({ path: 'supplierfollowup' })
                    state.menuactive = '4-2'
                } else {
                    router.push({ path: 'supplierfollowup' })
                    state.menuactive = '4-2'
                }
            }
        },
        keepCurrent(state){
            console.log(state.menuactive)
            let arr = []
            let index = state.tabs.findIndex(item => item.menuactive == state.menuactive)
            if(index==0){
                arr.push(state.tabs[0])
            }else{
                arr.push(state.tabs[0])
                arr.push(state.tabs[index])
            }
            state.tabs = arr
        },
        removeAll(state){
            state.tabs= [
                { menuactive: '0-0', title: '业务大厅', path: 'business_hall' }
            ]
            state.menuactive = '0-0'
            router.push({path:'/business_hall'})
        },
        stompMessage(state, data) {
            state.isPaytype = true
        },
        stompMsgCount(state,data){
            console.log('未读消息')
            state.msgCount = data
            localStorage.setItem('msgCount',data)
        },
        setMessage(state, data) {
            state.msgCount = data == 0 || data == null || data == undefined ? '' : data
        }
    },

    actions: {
        login(store, loginInfo) {
            const { orgId,username,storeCode,password,scene } = loginInfo
            return new Promise((resolve, reject) => {
                login({ orgId,phone: username.trim(),storeCode, password, scene })
                    .then(response => {
                        const res = response
                        // console.log(res)
                        store.commit('loginSet', res.data)
                        // console.log(res.data.token)
                        setYmsToken(res.data.token)
                        localStorage.setItem('departmentId',res.data.departmentId)
                        localStorage.setItem('orgId', res.data.orgId)
                        localStorage.setItem('orgName', res.data.orgName) //公司标识
                        localStorage.setItem('storeCode', res.data.storeCode) //门店编号
                        localStorage.setItem('storeName', res.data.storeName) //门店名称
                        localStorage.setItem('logoPath', res.data.logoPath) //公司logo
                        localStorage.setItem('domain', res.data.domain) //公司标识
                        localStorage.setItem('nickName', res.data.nickname) //用户昵称
                        localStorage.setItem('userName', res.data.userName) //用户名
                        localStorage.setItem('phone', res.data.phone) //联系电话
                        localStorage.setItem('state', res.data.state) //账户状态是否可用
                        localStorage.setItem('permissionList', res.data.permissionList) //菜单权限
                        localStorage.setItem('operatorId', res.data.operatorId) //操作员ID
                        localStorage.setItem('saleManCode', res.data.saleManCode) //操作员CODE
                        localStorage.setItem('msgCount', res.data.msgCount) //未读消息数量
                        localStorage.setItem('isVerify', res.data.isVerify) //登陆安全认证
                        localStorage.setItem('nasIpAddr', res.data.nasIpAddr) //nasIp

                        // localStorage.setItem('code', res.data.code)
                        // localStorage.setItem('userPost',res.data)//用户岗位信息
                        // localStorage.setItem('userInfo',res.data)//用户信息
                        resolve(res)
                    }).catch(err => {
                        console.log(err)
                        reject(err)
                        return Promise.reject(err)
                    })
            })
        },

        //store) {
        //     // console.log(store.state.name)
        //     const username = getToken()
        //     return new Promise((resolve, reject) => {
        //         getuser(username)
        //             .then(response => {
        //                 const res = response.data
        //                 console.log(res)
        //                     // store.commit('setUserInfo', res)
        //                 resolve()
        //             }).catch(err => {
        //                 console.log(err)
        //                 reject(err)
        //                 return Promise.reject(err)
        //             })
        //     })
        // },

    }
})

export default store