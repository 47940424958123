import { createApp } from 'vue'
import App from './App.vue'
// import preview from 'vue-phone-preview'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
// import {ElBacktop, ElCollapse, ElCollapseItem, ElScrollbar, Elform, ElButton, ElTable, ElInput, ElTabs, ElIcon, ElTabPane, ElCheckbox, ElRow, ElCol, ElMenu, ElSubMenu, ElMenuItemGroup, ElMenuItem, ElBadge, ElAvatar, ElPopover, ElDialog, ElTableColumn, ElPagination, ElAutocomplete, ElDatePicker, ElConfigProvider, ElRadioButton, ElRadioGroup, ElRadio, ElDrawer, ElOption, ElSelect, ElRate, ElStep, ElSteps, ElCard, ElMessage, ElSwitch, ElDivider, ElUpload, ElTree, ElDropdown,ElDropdownMenu,ElDropdownItem,ElTooltip,ElTag} from 'element-plus'
import { ElMessage } from 'element-plus'
import * as ElIconModules from '@element-plus/icons-vue'
import store from './store/index'
import router from './router/index'
import './permission'
import "amfe-flexible/index.js"
import './assets/reset.css'



// import {ElementTiptapPlugin} from 'element-tiptap'
// import 'element-tiptap/lib/index.css';

// const components = [ElBacktop, ElCollapse, ElCollapseItem,Elform, ElScrollbar, ElButton, ElTable, ElInput, ElTabs, ElIcon, ElTabPane, ElCheckbox, ElRow, ElCol, ElMenu, ElSubMenu, ElMenuItemGroup, ElMenuItem, ElBadge, ElAvatar, ElPopover, ElDialog, ElTableColumn, ElPagination, ElAutocomplete, ElDatePicker, ElConfigProvider, ElRadioButton, ElRadioGroup, ElRadio, ElDrawer, ElOption, ElSelect, ElRate, ElStep, ElSteps, ElCard, ElSwitch, ElDivider, ElUpload, ElTree,ElDropdown,ElDropdownMenu,ElDropdownItem,ElTooltip,ElTag]
const app = createApp(App)
app.directive('infinite-scroll',{
    beforeMount:(el,binding)=>{
        console.log(el,binding)
    }
})
app.use(router)
app.use(store)
app.use(ElementPlus,{locale: zhCn,})

// for (const component of components) {
//     app.component(component.name, component)
// }

app.config.globalProperties.$message = ElMessage;
// app.config.globalProperties.$confirm = ElMessageBox;

// app.use(QRCode)
// app.use(preview)
// app.use(router)
// app.use(ElementTiptapPlugin, {
//   lang: 'zh', // 设置语言为中文
// });

Object.keys(ElIconModules).forEach(function(key) {
    app.component(ElIconModules[key].name, ElIconModules[key])
})

app.mount('#app')