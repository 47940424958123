import axios from 'axios'
import QS from 'qs'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store/index'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    // baseURL: "http://iot.kirincs.com/printer/iot-web-pro/",  // url = base url + request url
    timeout: 30000 // request timeout
})
const whiteList = ['/kirincs-miniapp/wx/884d419841e05e43103a1840814d12cb/code/unlimited','/kirincs-user/api/store/user/register','/kirincs-store/wx/ma/login/passwd','/kirincs-store/wx/ma/scene']
// 请求拦截
service.interceptors.request.use(
    async (config)  => {
        const isInWhiteList = whiteList.includes(config.url);
        let parameters = { storeCode:localStorage.getItem('storeCode') }
        if(!isInWhiteList){
            //    config.data = {
            //         ...config.data,
            //         storeCode:localStorage.getItem('storeCode')
            //     }
                config.data = { ...config.data, ...parameters }
                if (config.method == 'get') {
                    config.url = `${config.url}?${QS.stringify(config.data)}`
                }
            }
        return config
    }
)

// 响应拦截
service.interceptors.response.use(

    response => {
        const res = response
        if (res.status == 200) {
            if (res.data.code == 0 || res.data.code == undefined) {
                return Promise.resolve(res.data)
            } else {
                return Promise.reject(res.data)
            }
        } else {
            if (res.data == null || res.data == undefined) {
                return Promise.reject(res.msg || 'Error')
            } else {
                return res
            }
        }
    },

    error => {
        return Promise.reject(error)
    }
)

export default service